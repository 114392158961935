import { gql } from "@apollo/client";

import * as MENUS from "@/constants/menus";
import {
  BlogInfoFragment,
  FeaturedImageFragment,
  MenuItemFragment,
} from "@/fragments";
import {
  Container,
  ContentWrapper,
  Main,
  SkipNavigationLink,
} from "@/components/atoms";
import { Blocks, Button, PreviewLoader, SEO } from "@/components/molecules";
import { Footer } from "@/components/organisms";
import type { Locale, PageData } from "@/types";
import JumpLinkSubnav from "@/components/molecules/JumpLinkSubnav";
import { getPostDataFromProps } from "@/utilities/getPostDataFromProps";
import { formatLocaleForGraphQL } from "@/utilities/formatLocaleForGraphQL";
import Banner from "@/components/molecules/Banner";
import Link from "next/link";
import { DashlaneIcon } from "@/components/icons";
import React from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import {
  GetEditorBlocksFragments,
  GetEditorBlocksKeys,
} from "@/fragments/fragmentFunctions";
import flatListToHierarchical from "@/utilities/flatListToHierarchical";

export default function Component(props: PageData) {
  const { locale } = useRouter();

  // Needed for previews to load on the client
  if (props.loading) {
    return <PreviewLoader />;
  }

  const {
    translated,
    siteTitle,
    footerMenu,
    content,
    seo,
    editorBlocks,
    contentType,
    jumpLinks,
    banner,
    uri,
    isFrontPage,
    landingPageFields,
  } = getPostDataFromProps(props, "page");

  // Get the right size for the logo
  const logo = props.data.page?.featuredImage?.node?.sourceUrl;
  const logoMaxHeight = 32;
  let logoHeight;
  let logoWidth;

  if (logo) {
    logoHeight = props.data.page?.featuredImage?.node.mediaDetails.height;
    logoWidth = props.data.page?.featuredImage?.node.mediaDetails.width;

    if (logoWidth && logoHeight) {
      let logoWidthMultiplier = logoMaxHeight / logoHeight;
      logoWidth = logoWidth * logoWidthMultiplier;
    } else {
      logoWidth = 200;
    }
    logoHeight = logoMaxHeight;
  }

  return (
    <>
      <SEO
        fullHead={seo.fullHead}
        availableLocales={translated}
        locale={props.locale}
        uri={uri}
        isFrontPage={isFrontPage}
      />
      <header className="sticky top-0 z-30">
        <SkipNavigationLink />
        {banner?.[0] && <Banner {...banner?.[0].attributes} />}
        <div className="header-background border border-l-0 border-r-0 border-t-0 border-grey-1">
          <div className="mx-auto max-w-7xl px-4 md:px-8">
            <div
              className="relative flex w-full items-center justify-between"
              style={{ height: "60px" }}
            >
              <Link
                href="/"
                className="mr-3 inline-flex items-center focus:outline-1 focus:outline-blue-8 md:mr-12"
                locale={locale}
              >
                <DashlaneIcon width="127" height="32" />
                {props.data.page?.featuredImage?.node?.sourceUrl && (
                  <span className="mx-3 text-4xl text-blue-8">+</span>
                )}
                {props.data.page?.featuredImage?.node?.sourceUrl && (
                  <Image
                    src={logo || ""}
                    alt={props.data.page.featuredImage.node.altText}
                    width={logoWidth}
                    height={logoHeight}
                  />
                )}
              </Link>
              {landingPageFields.buttonText && landingPageFields.buttonUrl && (
                <Button
                  onClick={() => {
                    window.location.href = landingPageFields.buttonUrl;
                  }}
                  {...(landingPageFields.ga4Id
                    ? { "data-item": landingPageFields.ga4Id }
                    : {})}
                >
                  {landingPageFields.buttonText}
                </Button>
              )}
            </div>
          </div>
        </div>
      </header>
      <Main>
        {jumpLinks.length ? (
          <JumpLinkSubnav
            jumpLinks={jumpLinks}
            excerpt={props.data.page.excerpt}
            title={props.data.page.title}
          />
        ) : null}
        <Container tagName="article">
          {editorBlocks.length > 0 ? (
            <Blocks
              blocks={flatListToHierarchical(editorBlocks, {
                idKey: "id",
                parentKey: "parentClientId",
                childrenKey: "innerBlocks",
              })}
              contentType={contentType}
            />
          ) : (
            <ContentWrapper content={content} />
          )}
        </Container>
      </Main>
      <Footer
        title={siteTitle}
        menuItems={footerMenu}
        availableLocales={translated}
        locale={props.locale}
        landing={true}
      />
    </>
  );
}

Component.query = gql`
  ${BlogInfoFragment}
  ${GetEditorBlocksFragments()}
  ${FeaturedImageFragment}
  ${MenuItemFragment}
  query GetPageData(
    $databaseId: ID!
    $footerLocation: MenuLocationEnum
    $asPreview: Boolean = false
    $locale: LanguageCodeFilterEnum = EN
  ) {
    page(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      title
      content
      ${GetEditorBlocksKeys()}
      excerpt
      seo {
        fullHead
      }
      uri
      isFrontPage
      contentType {
        node {
          name
        }
      }
      landingPageFields {
        buttonText
        buttonUrl
        ga4Id
      }
      ...FeaturedImageFragment
    }
    generalSettings {
      ...BlogInfoFragment
    }
    footerMenuItems: menuItems(
      where: { location: $footerLocation, language: $locale }
      first: ${MENUS.NUMBER_OF_MENU_ITEMS}
    ) {
      nodes {
        ...MenuItemFragment
      }
    }
  }
`;

Component.variables = (
  { databaseId }: { databaseId: string },
  { asPreview, locale }: { asPreview?: boolean; locale: Locale }
) => {
  return {
    asPreview,
    footerLocation: MENUS.newFooterLocation(locale),
    locale: formatLocaleForGraphQL(locale),
    databaseId,
  };
};
