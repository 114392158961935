import Cookies from "js-cookie";
import FELogger from "@/utilities/monitoring/feLogger";
import { setMarketoAsProvider } from "../loadLeanData";
import { marketoFormApi } from "./marketoFormApi";

export interface MarketoFields {
  Lead_Category__c?: string;
  Language_Preference__c?: string;
  product_plan__c?: string;
  Marketo_Form_ID?: number;
}

const logger = new FELogger();

export const munchkinImposter = {
  sendLead<T extends MarketoFields>(
    fields: T,
    formId: number | string,
    successRedirect: string | null,
    successCallback?: () => void
  ) {
    try {
      // marketoFormApi is loaded via third-party script
      // so we need to make sure we have it before proceeding
      if (!marketoFormApi) {
        throw new Error("marketoFormApi not found (MarketoForm/index.tsx)");
      }
      const country = window.pageConfig?.country;
      const userFunnelCookie = JSON.parse(
        Cookies.get("userFunnelCookie") || "{}"
      );
      const userFunnelCookieString = Cookies.get("userFunnelCookie") ?? "{}";
      const marketoForms = marketoFormApi.allForms();
      if (typeof formId === "string") {
        formId = parseInt(formId, 10);
      }

      let marketoForm: any = marketoFormApi.allForms()[0];

      marketoForms.forEach((form) => {
        if (form.getId() === formId) {
          marketoForm = form;
        }
      });

      const handleWebFormUrl = (url: Location) => {
        // Special handling for partnerships, affiliates, and resellers to accommodate Andrew K's legacy Marketo logic
        if (
          url.pathname.includes("affiliate") ||
          url.pathname.includes("reseller")
        ) {
          return [url.origin, url.pathname.split("/").pop()].join("/");
        } else {
          return [url.origin, url.pathname].join("");
        }
      };

      // VWO_campaign = “_vis_opt_exp_404_split”
      // VWO_variant = “Variant1"
      // VWO_campaign2 = “_vis_opt_exp_404_combi”
      // VWO_variant2 = “Variant1"
      // VWO_campaign3 = “_vis_opt_exp_419_split”
      // VWO_variant3 = “Control”

      const createVWOMarketoFields = () => {
        const cookiePairs = document.cookie.split(";");

        const visOptExpFields: Record<string, string> = {};
        const decodedValueMap: Record<string, string> = {
          "1": "Control",
          "2": "Variant1",
          "3": "Variant2",
          "4": "Variant3",
        };

        for (const cookiePair of cookiePairs) {
          const [cookieName, cookieValue] = cookiePair.trim().split("=");

          if (cookieName?.startsWith("_vis_opt_exp_") && cookieValue) {
            const decodedValue = decodeURIComponent(cookieValue);
            visOptExpFields[`VWO_campaign${decodedValue}`] = cookieName ?? "";
            visOptExpFields[`VWO_variant${decodedValue}`] =
              decodedValueMap[decodedValue] ?? "";
          }
        }

        return visOptExpFields;
      };

      const vwoMktoFields = createVWOMarketoFields();

      const fieldsToAdd = {
        Web_Form_URL__c: handleWebFormUrl(window.location),
        // B2B prefers having stringified null value
        // instead of an empty string which is the
        // default for userFunnelCookie properties
        utm_campaign__c: userFunnelCookie.utm_campaign || "null",
        utm_content__c: userFunnelCookie.utm_content || "null",
        utm_medium__c: userFunnelCookie.utm_medium || "null",
        utm_source__c: userFunnelCookie.utm_source || "null",
        utm_term__c: userFunnelCookie.utm_term || "null",
        utm_campaign_lc__c: userFunnelCookie.last_click_campaign || "null",
        utm_content_lc__c: userFunnelCookie.last_click_content || "null",
        utm_medium_lc__c: userFunnelCookie.last_click_medium || "null",
        utm_source_lc__c: userFunnelCookie.last_click_source || "null",
        utm_term_lc__c: userFunnelCookie.last_click_term || "null",
        cookieJson__c: userFunnelCookieString,
        gclid__c: userFunnelCookie.gclid || "null",
        Country: country,
        ...vwoMktoFields,
        LD_BookIt_Log_ID__c: "null",
      };

      const hiddenFields = Object.assign(fieldsToAdd, fields);

      if (marketoForm) {
        if (window.LDBookItV2) {
          hiddenFields.LD_BookIt_Log_ID__c = window.LDBookItV2.getUniqueId();
        }

        marketoForm.addHiddenFields(hiddenFields);

        if (window.LDBookItV2) {
          setMarketoAsProvider();
          window.LDBookItV2.saveFormData(hiddenFields);
        }

        marketoForm.onSuccess(() => {
          logger.info("munchkinImposter", "Successfully submitted form", {
            formId,
          });
          if (successCallback) {
            successCallback();
          } else {
            if (successRedirect) {
              window.location.href = successRedirect;
            }
          }

          return false;
        });
        window.FormsPlus.onSubmitError((error: any) => {
          logger.error("munchkinImposter", "Error on Marketo API request", {
            error,
            hiddenFields: fieldsToAdd,
            formId,
          });
        });
        logger.info("munchkinImposter", "Submitting form", {
          formId,
        });
        const originalSubmit = marketoForm.submit;
        marketoForm.submit = function () {
          const isFormValid = Object.keys(fields).every(
            (field: string) => fields[field as keyof MarketoFields] !== ""
          );
          if (!isFormValid) {
            logger.error(
              "munchkinImposter",
              "Form submission blocked due to empty fields",
              {
                userAgent: navigator.userAgent,
                formFields: Object.keys(fields).map((field: string) => ({
                  name: field,
                  value: fields[field as keyof MarketoFields],
                })),
              }
            );
            return false;
          }
          return originalSubmit.apply(this, arguments);
        };
        marketoForm.submit();
      } else {
        logger.error("munchkinImposter", "Error on Marketo API request", {
          error: "No Marketo form found",
          hiddenFields: fieldsToAdd,
          formId,
        });
      }

      // Returning true here means there is probably a scenario where marketoForm.submit() fails, but we still redirect
      // from sendLeadToMarketo
      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      logger.error("munchkinImposter", "Error on Marketo API request", {
        error,
        formId,
      });

      return false;
    }
  },
};
