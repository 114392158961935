import React, { useState } from "react";
import cn from "classnames";
import { RichText } from "@/components/atoms";
import BlockContent from "../molecules/BlockContent";
import {
  marginBMd,
  textHeadlineNeutral,
  textHeadlineInverse,
  h1Text,
  textBodyInverse,
  textBodyNeutral,
  marginBLg,
} from "@/constants/standardCSSClasses";
import { Block } from "@/types";
import { Blocks } from "@/components/molecules";
import PricingGroup from "@/components/molecules/PricingGroup";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import ToggleButtons, {
  TogglesInterface,
} from "@/components/molecules/ToggleButtons";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";
import { useWindowSize } from "@/hooks/useWindowSize";
import { MOBILE_BREAK } from "@/constants/utility";
import { useTouchSlider } from "@/hooks/useTouchSlider";

interface Props extends AttributesExtension {
  includeContent?: boolean;
  includeToggle?: boolean;
  heading?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  variant?: string;
  groupColumns?: boolean;
  groupOneName?: string;
  groupTwoName?: string;
  ga4SectionId?: string;
  innerBlocks?: Block[];
  toggle?: TogglesInterface;
  paddingTop?: string;
  paddingBottom?: string;
}

export default function PricingBlock({
  includeContent = false,
  includeToggle = false,
  heading = "",
  headingOptions = {
    tag: 2,
  },
  content = "",
  variant = "white",
  groupColumns,
  groupOneName = "",
  groupTwoName = "",
  toggle,
  innerBlocks,
  blockContentProps,
  paddingTop,
  paddingBottom,
}: Props) {
  const { width: windowWidth } = useWindowSize();
  const isMobileDevice = windowWidth < MOBILE_BREAK;
  const [addMarginTop, setAddMarginTop] = useState(false);
  const columnCount = innerBlocks?.length ? innerBlocks.length : 0;

  const groupOne: Block[] = [];
  const groupTwo: Block[] = [];
  const groupedPricing = groupColumns && columnCount > 3;

  if (groupedPricing) {
    const groupInnerBlocks = innerBlocks?.map((each, index) => {
      return {
        ...each,
        attributes: {
          ...each.attributes,
          columns: columnCount,
          index: index,
          variant: variant,
          groupColumns: groupColumns,
          headingOptions: {
            tag: headingOptions.tag + 2,
          },
        },
      };
    });

    groupInnerBlocks?.forEach((block, index) => {
      if (index < 2) {
        groupOne.push(block);
      } else {
        groupTwo.push(block);
      }
    });
  }

  const columns = innerBlocks?.length ? (
    <Blocks
      blocks={innerBlocks.map((each, index) => {
        each.attributes.variant = variant;
        each.attributes.columns = columnCount;
        each.attributes.index = index;
        each.attributes.groupColumns = groupColumns;
        each.attributes.setAddMarginTop = setAddMarginTop;

        if (each.attributes.headingOptions === undefined) {
          each.attributes.headingOptions = {};
        }
        each.attributes.headingOptions.tag = headingOptions.tag + 1;

        return each;
      })}
    />
  ) : null;

  const {
    wrapClassNames,
    sliderInnovationClassNames,
    sliderDots,
    handleTouchStart,
    handleTouchEnd,
    handleTouchMove,
    sliderRef,
  } = useTouchSlider(columnCount, variant, undefined, true, [], true);

  const headerClassNames = cn("md:w-[623px] m-auto text-center", h1Text, {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
    [marginBMd]: includeContent || includeToggle,
    "pb-8 md:pb-[60px] mb-0": !includeContent && !includeToggle,
  });

  const pTextClassNames = cn(marginBLg, "text-center", {
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
  });

  const wrapper = cn("relative", wrapClassNames);

  return (
    <BlockContent
      variant={variant}
      width="full"
      constrain
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      {...blockContentProps}
    >
      <CustomHeading
        heading={heading}
        headingOptions={headingOptions}
        headingClassNames={headerClassNames}
      />
      {includeContent && (
        <RichText className={pTextClassNames} tag="p">
          {content}
        </RichText>
      )}
      {includeToggle && toggle && <ToggleButtons toggle={toggle} />}
      {isMobileDevice && (
        <>
          <div className={wrapper}>
            <div
              className={sliderInnovationClassNames}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
              onTouchMove={handleTouchMove}
              ref={sliderRef}
            >
              {columns}
            </div>
          </div>
          {columnCount > 1 && sliderDots}
        </>
      )}
      {!isMobileDevice && (
        <div
          className={cn("w-full items-stretch", {
            "md:flex": columnCount < 4 || !groupColumns,
            "lg:flex": groupedPricing,
            "mt-20": addMarginTop,
          })}
        >
          {groupedPricing && groupOne && groupTwo ? (
            <>
              <PricingGroup
                columns={groupOne}
                columnCount={columnCount}
                headingOptions={{
                  ...headingOptions,
                  tag: headingOptions.tag + 1,
                }}
                name={groupOneName}
                variant={variant}
                number={1}
              />
              <PricingGroup
                columns={groupTwo}
                columnCount={columnCount}
                headingOptions={{
                  ...headingOptions,
                  tag: headingOptions.tag + 1,
                }}
                name={groupTwoName}
                variant={variant}
                number={2}
              />
            </>
          ) : (
            columns
          )}
        </div>
      )}
    </BlockContent>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupPricing";

PricingBlock.displayName = BLOCK_NAME;

PricingBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          className
          content
          ga4SectionId
          groupColumns
          groupOneName
          groupTwoName
          heading
          headingOptions
          includeContent
          includeToggle
          metadata
          toggle
          variant
          paddingTop
          paddingBottom
        }
      }
    }
  `,
};
