import React, { useEffect } from "react";
import cn from "classnames";
import { RichText } from "@/components/atoms";
import {
  marginBMd,
  textHeadlineNeutral,
  textHeadlineInverse,
  textBodyInverse,
  textBodyNeutral,
  marginTMd,
  h5Text,
  marginBXS,
  borderNeutral,
  borderInverse,
  backgroundInverse,
  backgroundNeutral,
  textBodyCatchyNeutral,
  h4Text,
  marginTLg,
  borderRecommended,
} from "@/constants/standardCSSClasses";
import { Block } from "@/types";
import { CustomButtonInterface } from "@/components/molecules/CustomButton";
import CustomButtonGroup from "@/components/molecules/CustomButtonGroup";
import { Blocks } from "@/components/molecules";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import { useTeamOffers } from "@/hooks/useTeamOffers";
import { useTranslate } from "@/hooks/useTranslate";
import { useTouchSlide } from "@/hooks/useTouchSlider";
import { PlanSegment } from "@/types/PlanSegment";
import { BUY_FORM_VAT } from "@/i18n/translations";
import { useDefaultWebOffers } from "@/hooks/useDefaultWebOffers";
import Price from "@/components/molecules/Price";
import Icon, { IconInterface } from "@/components/molecules/Icon";
import Tooltip from "../molecules/Tooltip";
import { info as InfoIcon } from "@/constants/icons";
import DiscountedPrice from "../molecules/DiscountedPrice";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";
import { useWindowSize } from "@/hooks/useWindowSize";
import { MOBILE_BREAK } from "@/constants/utility";

export interface PricingColumnInterface {
  heading?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  variant: string;
  groupColumns: boolean;
  columns: number;
  index: number;
  includeTag?: boolean;
  tag?: string;
  price?: string;
  strikeThroughPrice?: string;
  includeStrikedPrice?: boolean;
  per?: string;
  period?: string;
  buttonsNumber: number;
  buttonOne?: CustomButtonInterface;
  buttonTwo?: CustomButtonInterface;
  innerBlocks?: Block[];
  planSegment: PlanSegment;
  useIcon?: boolean;
  icon?: IconInterface;
  displayFeaturesRight?: boolean;
  discountGiven?: string;
  includeAboveHeading?: boolean;
  aboveHeading?: string;
  aboveHeadingBackgroundColor?: string;
  displayCustomPrice?: boolean;
  customPrice?: string;
  isRecommended?: boolean;
  recommendedLabel?: string;
  setAddMarginTop: (value: boolean) => void;
  includeTooltip?: boolean;
  tooltip?: string;
}

export default function PricingColumnBlock({
  heading,
  headingOptions = {
    tag: 3,
  },
  content,
  variant,
  groupColumns,
  setAddMarginTop,
  columns,
  index,
  includeTag = false,
  tag,
  per,
  period,
  buttonsNumber = 2,
  buttonOne = {
    text: "Click Here",
    variant: "dark",
  },
  buttonTwo = {
    text: "Click Here",
    variant: "light",
  },
  innerBlocks,
  planSegment = "business",
  useIcon = false,
  icon = { source: "" },
  displayFeaturesRight = false,
  includeStrikedPrice = false,
  discountGiven = "0",
  includeAboveHeading = false,
  aboveHeading,
  aboveHeadingBackgroundColor,
  displayCustomPrice = false,
  customPrice,
  isRecommended = false,
  recommendedLabel = "",
  includeTooltip = false,
  tooltip,
}: PricingColumnInterface) {
  const { width: windowWidth } = useWindowSize();
  const isMobileDevice = windowWidth < MOBILE_BREAK;
  const { translate } = useTranslate();
  const { teamOffers } = useTeamOffers({
    planSegment,
    discountGiven: Number(discountGiven),
  });
  const { webOffers } = useDefaultWebOffers({
    planSegment,
    discountGiven: Number(discountGiven),
  });
  const groupedPricing = (columns === 4 || columns === 5) && groupColumns;
  const { slideWrapClass } = useTouchSlide(1, true);

  const items = innerBlocks?.length ? (
    <Blocks
      blocks={innerBlocks.map((each) => {
        each.attributes.variant = variant;
        return each;
      })}
    />
  ) : null;

  const shouldAddRecommended =
    isRecommended && !(groupColumns && columns === 4);

  useEffect(() => {
    if (shouldAddRecommended) {
      setAddMarginTop(true);
    }
  }, [isRecommended]);

  const wrapClassNames = cn("flex items-stretch w-full", {
    "lg:w-1/2": columns === 4 && groupColumns,
    "md:w-1/4": columns === 4 && !groupColumns,
    "md:w-1/3": columns === 3,
    "md:w-1/2": columns === 2,
    "md:w-full justify-center": columns === 1,
    "md:justify-end": columns === 2 && index === 0,
    "mt-10 md:mt-0": shouldAddRecommended,
    [slideWrapClass]: isMobileDevice,
  });

  const blockClassNames = cn("w-full p-8 border border-solid", {
    "md:max-w-md": columns !== 1 || !displayFeaturesRight,
    [borderInverse]: !shouldAddRecommended && variant === "dark",
    [borderNeutral]: !shouldAddRecommended && variant !== "dark",
    [borderRecommended]: shouldAddRecommended,
    relative: shouldAddRecommended,
    "md:ms-6": (columns < 4 && index > 0) || !groupColumns,
    "md:border-l-0":
      groupedPricing && (index === 1 || index === 3 || index === 4),
    "border-t-0": groupedPricing && !isMobileDevice,
    "mt-4 md:mt-0 rounded": columns < 4 || !groupColumns,
    "md:rounded-es-lg":
      (groupedPricing && index === 0) || (groupedPricing && index === 2),
    "rounded-es-lg md:rounded-es-none rounded-ee-lg":
      (groupedPricing && index === 1) ||
      (columns === 5 && index === 4) ||
      (columns === 4 && index === 3),
    "md:flex md:justify-between": displayFeaturesRight,
  });

  const headerClassNames = cn(marginBXS, {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
    [h4Text]: columns < 4,
    [h5Text]: columns >= 4,
    "mt-2": includeAboveHeading,
  });

  const pTextClassNames = cn("text-sm lg:text-base", marginBMd, {
    "lg:h-[40px]": columns === 5,
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
  });

  const priceClassNames = cn("text-4xl font-medium", {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const strikedPriceClassNames = cn("line-through opacity-30 mr-4");

  const perClassNames = cn("text-sm md:text-base block", {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const tagClassNames = cn(
    "inline-block ms-2 rounded py-1.5 px-1.5 font-medium uppercase",
    {
      [backgroundInverse]: variant === "light",
      [backgroundNeutral]: variant !== "light",
      [textHeadlineInverse]: variant === "light",
      [textBodyCatchyNeutral]: variant !== "light",
      "text-[10px] px-1.5": columns === 5,
      "px-2.5 text-xs": columns < 5,
    }
  );

  const aboveHeadingClassNames = cn(
    "inline-block rounded py-1.5 px-2.5 font-medium text-xs uppercase",
    marginBXS,
    {
      "bg-grey-005 text-blue-8": aboveHeadingBackgroundColor !== "green",
      "bg-[#3EC69B] text-white": aboveHeadingBackgroundColor === "green",
    }
  );

  const buttons = [];

  if (buttonsNumber > 0) {
    buttons.push({
      ...buttonOne,
      variant: variant === "dark" ? "white" : "dark",
      size: "md",
      ga4PlanSegment: planSegment,
    });
  }

  if (buttonsNumber > 1) {
    buttons.push({
      ...buttonTwo,
      variant: variant === "dark" ? "dark" : "light",
      size: "md",
      ga4PlanSegment: planSegment,
    });
  }

  const showDiscount =
    includeStrikedPrice && discountGiven && discountGiven !== "0";

  return (
    <div className={wrapClassNames}>
      <div className={blockClassNames}>
        {shouldAddRecommended && (
          <div className="absolute left-[-1px] top-[-40px] w-[calc(100%+2px)] rounded-t-md border border-solid border-blue-8 bg-blue-8 py-3 text-center text-white">
            <RichText className="m-0" tag="span">
              {recommendedLabel}
            </RichText>
          </div>
        )}
        <div className={cn({ "md:w-[35%]": displayFeaturesRight })}>
          {includeAboveHeading && (
            <RichText className={aboveHeadingClassNames} tag="span">
              {aboveHeading}
            </RichText>
          )}
          <div className="flex items-center">
            <CustomHeading
              heading={heading}
              headingOptions={headingOptions}
              headingClassNames={headerClassNames}
            />
            {includeTooltip && tooltip && (
              <Tooltip
                delay={0}
                direction="bottom"
                content={tooltip}
                textAlign="left"
              >
                <span
                  className={cn("ml-1 mt-[-10px] block h-[16px] w-[16px]", {
                    "fill-white": variant === "dark",
                    "fill-grey-6": variant !== "dark",
                  })}
                >
                  <InfoIcon width="16px" />
                </span>
              </Tooltip>
            )}
          </div>
          <RichText className={pTextClassNames} tag="p">
            {content}
          </RichText>
          <div className="mb-2 flex flex-wrap items-center">
            <span className={priceClassNames}>
              {useIcon && !displayCustomPrice && (
                <Icon
                  {...icon}
                  variant={variant}
                  width={36}
                  iconClassName="mt-[-4px]"
                />
              )}
              {!useIcon && !displayCustomPrice && (
                <div>
                  {showDiscount && (
                    <>
                      <span className={strikedPriceClassNames}>
                        <Price
                          planSegment={planSegment}
                          teamOffers={teamOffers}
                          webOffers={webOffers}
                        />
                      </span>
                      <DiscountedPrice
                        planSegment={planSegment}
                        teamOffers={teamOffers}
                        webOffers={webOffers}
                      />
                    </>
                  )}
                  {!showDiscount && (
                    <Price
                      planSegment={planSegment}
                      teamOffers={teamOffers}
                      webOffers={webOffers}
                    />
                  )}
                </div>
              )}
              {!useIcon && displayCustomPrice && (
                <RichText className={priceClassNames} tag="span">
                  {customPrice}
                </RichText>
              )}
            </span>
            {(webOffers?.hasPriceWithoutVat ||
              webOffers?.currency === "eur" ||
              teamOffers?.planPrices.currency === "eur") &&
              planSegment !== "free" && (
                <div className="ml-1 ms-2 flex rounded bg-blue-1 px-2.5 py-1.5 text-xs font-medium uppercase text-blue-5">
                  <span className="mx-0 my-auto">+</span>
                  <p className="mx-0 my-auto">{translate(BUY_FORM_VAT)}</p>
                </div>
              )}
            {includeTag && tag && (
              <RichText className={tagClassNames} tag="span">
                {tag}
              </RichText>
            )}
          </div>
          <RichText className={perClassNames} tag="span">
            {per}
          </RichText>
          <RichText className={perClassNames} tag="span">
            {period}
          </RichText>
          <CustomButtonGroup
            className={cn(marginTMd, "md:min-h-[96px]")}
            align="start"
            buttons={buttons}
            column={true}
          />
        </div>
        <div
          className={cn({
            [marginTLg]: !displayFeaturesRight,
            "mt-8 md:ml-4 md:mt-0 md:w-1/2": displayFeaturesRight,
          })}
        >
          {items}
        </div>
      </div>
    </div>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupPricingColumn";

PricingColumnBlock.displayName = BLOCK_NAME;

PricingColumnBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          aboveHeadingBackgroundColor
          buttonOne
          buttonTwo
          buttonsNumber
          className
          content
          customPrice
          discountGiven
          displayCustomPrice
          displayFeaturesRight
          heading
          icon
          includeAboveHeading
          includeStrikedPrice
          includeTag
          metadata
          per
          period
          planSegment
          tag
          useIcon
          isRecommended
          recommendedLabel
          tooltip
          includeTooltip
        }
      }
    }
  `,
};
